<template>
  <div style="position: relative; min-height: 100vh;">
    <v-expand-x-transition hide-on-leave>
      <PDFSidebar
        v-show="sidebarOn"
        v-bind="{matches, isOutlineEnabled: sidebarOn, pdfOutline, pages, scale: 1, currentPage, pageCount}"
        @scrollToMatch="scrollToMatch"
        @scrollToOutline="scrollToOutline"
        @page-focus="scrollToPage"
      />
    </v-expand-x-transition>

    <!-- TODO: scroll-behavior: smooth; -->
    <div
      ref="viewerContainer"
      id="viewerContainer"
      :style="`transition: width 0.3s ease, left 0.3s ease;
        width: ${sidebarOn ? 85 : 100}%; left: ${sidebarOn ? 15 : 0}%;`"
    >
      <div
        id="viewer"
        class="pdfViewer"
      ></div>
    </div>
  </div>
</template>

<script>
import API from '@api'
import axios from 'axios'
import {
  mdiLightbulb,
  mdiPageLayoutBody,
  mdiFormatLetterMatches,
  mdiPackage,
  mdiTextBox,
  mdiDotsVertical,
  mdiClose,
  mdiMagnify,
  mdiArrowUp,
  mdiArrowDown,
  mdiViewList
} from '@mdi/js'

import { getPdfTextContent, getResourceUrl } from '@utils'
import { range } from 'lodash'

import PDFSidebar from '@components/PDF/Sidebar.vue'
import { BACKGROUND_DARK_COLOR } from '@utils/constants';
import { mapGetters, mapMutations } from 'vuex'
import { distance } from 'fastest-levenshtein'

require('pdfjs-dist/legacy/web/pdf_viewer.css')

const pdfjsLib = require('pdfjs-dist/legacy/build/pdf')
const pdfjsViewer = require('pdfjs-dist/legacy/web/pdf_viewer')

pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/legacy/build/pdf.worker.min')

function getPages(pdfDocument) {
  const allPages = range(1, pdfDocument.numPages + 1).map(number => pdfDocument.getPage(number))
  return Promise.all(allPages)
}

function getSimilarity(str1, str2) {
  // A function to calculate Levenshtein Distance
  const levenshteinDistance = distance(str1, str2);
  const maxLength = Math.max(str1.length, str2.length);

  return (1 - levenshteinDistance / maxLength);
}

function findTextInPage(n, textStr) {
  return new Promise((resolve, reject) => {
    // Get the div matching the page number
    let pageDiv = document.querySelector(`div.page[data-page-number="${n}"]`);
    if (!pageDiv) reject('Page div not found');

    setTimeout(() => {
      // Get all span elements in the div
      let spans = Array.from(pageDiv.getElementsByTagName('span'));

      spans.forEach(span => {
        if(span.textContent && getSimilarity(span.textContent, textStr) > 0.7) {
          // Resolve the promise if the span contains the text string
          resolve(span); 
        }
      })

      // If no match is found, reject the promise
      reject('No match found');
    }, 500)
  });
}

export default {
  components: {
    PDFSidebar
  },

  async mounted() {
    try {
      const content = await API().get(`content/${this.user.id}`, {
        params: {
          id: this.$route.query.id
        }
      })

      this.SET_CONTENT(content)

      const pdf = await API().get(`pdf/${this.user.id}`, {
        params: {
          id: content.media
        }
      })

      // Sets the file and fileType to reference media on the Anna Chamber
      this.SET_FILE(pdf)
      this.SET_FILE_TYPE('pdf')

      const DEFAULT_URL = getResourceUrl(pdf.url);
      const container = document.getElementById('viewerContainer');
      const eventBus = new pdfjsViewer.EventBus();

      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      const pdfFindController = new pdfjsViewer.PDFFindController({
        eventBus,
        linkService: pdfLinkService,
      });

      const pdfViewer = new pdfjsViewer.PDFViewer({
        container,
        eventBus,
        textLayerMode: 2,
        linkService: pdfLinkService,
        findController: pdfFindController
      });

      pdfLinkService.setViewer(pdfViewer);

      this.pdfViewer = pdfViewer
      this.pdfFindController = pdfFindController
      this.pdfLinkService = pdfLinkService
      this.eventBus = eventBus

      eventBus.on('pagesinit', async () => {
        this.pages = await getPages(this.pdfDocument)
        pdfViewer.currentScaleValue = 1;
        this.SET_PDF_SCALE(pdfViewer.currentScale)
        pdfViewer.pagesRotation = 0;
      });

      // Loading document.
      const loadingTask = pdfjsLib.getDocument({
        url: DEFAULT_URL
      });

      (async () => {
        const pdfDocument = await loadingTask.promise

        this.SET_PAGE_COUNT(pdfDocument.numPages)

        pdfViewer.setDocument(pdfDocument)
        pdfLinkService.setDocument(pdfDocument, null)

        this.pdfDocument = pdfDocument

        const transcription = await getPdfTextContent(this.pdfDocument)
        const transcriptionLen = transcription.map(({ textContent }) => textContent).join('').length
        if (transcriptionLen === 0 && pdf.embeddingStatus != 'UNDEFINED') {
          // If pdf has no selectable text, sets embeddingStatus as UNDEFINED
          API().put(`pdf/${this.user.id}`, {
            embeddingStatus: 'UNDEFINED'
          }, {
            params: {
              id: pdf._id
            }
          })

          pdf.embeddingStatus = 'UNDEFINED'
          this.SET_FILE(pdf)
        } else {
          // If the pdf has selectable text but has no corresponding
          // index in the vector database, tries to upload it again
          // to the vector database
          const contextLen = await this.getContextLen()

          if (contextLen === 0) {
            pdf.embeddingStatus = 'MISSING'
            this.SET_FILE(pdf)

            let formData = new FormData();

            // Rename the form data file to pdf._id
            const pdfData = await pdfDocument.getData(); 
            const renamedPdfFile = new File([pdfData], `${pdf._id}.pdf`, {
              type: 'application/pdf'
            });

            // Assuming `this.pdfFile` is the PDF file to upload:
            formData.append('file', renamedPdfFile); // Append renamed pdf file to formData

            const response = await axios.post(`${process.env.VUE_APP_LANGCHAIN_API}/upload_pdf`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              withCredentials: false
            })

            // updates the pdf context status
            let embeddingStatus = response.data === -1 ? 'MISSING' : 'CREATED'
            API().put(`pdf/${this.user.id}`, { embeddingStatus }, {
              params: {
                id: pdf._id
              }
            })
            pdf.embeddingStatus = embeddingStatus
            this.SET_FILE(pdf)
          }
        }

        this.pdfOutline = await pdfDocument.getOutline() // TODO: show outline in the left menu
        this.pageCount = this.pdfDocument.numPages

        this.SET_PDF_DOCUMENT(this.pdfDocument) // TODO: double check the utility of this

        if (this.$route.query.page) {
          const page = Number(this.$route.query.page)
          this.scrollToPage(page)
        }
      })();
    } catch (err) {
      // TODO: handle error
    }
  },

  beforeDestroy() {
    this.SET_FILE({})
    this.SET_FILE_TYPE('')
  },

  data() {
    return {
      mdiLightbulb,
      mdiPageLayoutBody,
      mdiFormatLetterMatches,
      mdiPackage,
      mdiTextBox,
      mdiDotsVertical,
      mdiClose,
      mdiMagnify,
      mdiArrowUp,
      mdiArrowDown,
      mdiViewList,

      pages: [],
      pdfViewer: null,
      pdfFindController: null,
      eventBus: null,
      pdfLinkService: null,
      pdfOutline: [],
      pageCount: 0,
      toolbarSignal: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      myProfile: 'profile/id',
      myName: 'profile/name',
      myAvatar: 'profile/avatar',
      settings: 'settings',
      fit: 'pdf/fit',
      scale: 'pdf/scale',
      rotation: 'pdf/rotation',
      currentPage: 'pdf/currentPage',
      paginatorOn: 'pdf/paginatorOn',
      textQuery: 'pdf/textQuery',
      querySwitch: 'pdf/querySwitch',
      sidebarOn: 'pdf/sidebarOn',
      searchNext: 'pdf/searchNext',
      searchPrevious: 'pdf/searchPrevious',
      isSearchListEnabled: 'pdf/isSearchListEnabled',
      isPreviewEnabled: 'pdf/isPreviewEnabled',
      isOutlineEnabled: 'pdf/isOutlineEnabled',
      sources: 'pdf/sources',
      file: 'media/file',
      fileType: 'media/fileType',
      matches: 'pdf/matches'
    }),

    backgroundColor() {
      return this.$vuetify.theme.dark
        ? BACKGROUND_DARK_COLOR
        : 'white'
    },

    numCols() {
      return this.isSearchListEnabled ? 8 : 12
    }
  },

  watch: {
    sources: {
      deep: true,
      async handler ([{ page_content, metadata }]) {
        // TODO: finish overlay
        return 
        setTimeout(async () => {
          // Review
          let firstDiv = await findTextInPage(metadata.page + 1, page_content.split('HS-BREAK').shift().split('\n').shift().trim());
          let lastDiv = await findTextInPage(metadata.page + 1, page_content.split('HS-BREAK').pop().split('\n').pop().trim());

          let textSpan, lastTextSpan;
          if (firstDiv) {
            if (firstDiv.tagName != 'SPAN' || firstDiv.role != 'presentation') {
              textSpan = firstDiv.getElementsByTagName('span')[0]
              lastTextSpan = lastDiv.getElementsByTagName('span')[0]
            } else {
              textSpan = firstDiv
              lastTextSpan = lastDiv
            }

            window.textSpan = textSpan
            window.lastTextSpan = lastTextSpan

            if (textSpan) {
              // create overlay
              const overlay = document.createElement('div');
              overlay.style.position = 'absolute';
              overlay.style.backgroundColor = 'rgba(128, 0, 128, 0.15)'; // light purple, near transparent color

              // get textSpan position and lastDiv position
              const textSpanStyle = textSpan.style;
              const lastSpanStyle = lastTextSpan.style; 

              const width = Math.max((textSpanStyle.fontSize.split('.').shift() * textSpan.textContent.length/2), 
              (lastSpanStyle.fontSize.split('.').shift() * lastTextSpan.textContent.length/2))

              // compute overlay size and position
              overlay.style.width = width + 'px';
              overlay.style.height = (lastSpanStyle.top.split('.').shift() - textSpanStyle.top.split('.').shift()) + 'px';
              overlay.style.left = (textSpanStyle.left.split('.').shift() - 30) + 'px';
              overlay.style.top = textSpanStyle.top.split('.').shift() + 'px';

              // find first parent div with class "canvasWrapper"
              let parentDiv =  firstDiv.parentElement

              if (!parentDiv.classList.contains('page')) parentDiv = parentDiv.parentElement

              // append overlay to the parent div
              if (parentDiv) {
                parentDiv.appendChild(overlay);
              }
            }
          }
        }, 300);
      }
    },

    // see https://github.com/mozilla/pdf.js/issues/12731
    searchNext() {
      this.eventBus.dispatch("find", {
        type: "again",
        query: this.textQuery,
        findPrevious: false,
        caseSensitive: false,
        phraseSearch: true        
      });
    },

    searchPrevious() {
      this.eventBus.dispatch("find", {
        type: "again",
        query: this.textQuery,
        findPrevious: true,
        caseSensitive: false,
        phraseSearch: true       
      });
    },

    textQuery(queryStr) {
      this.eventBus.dispatch("find", {
        type: "",
        query: queryStr,
        caseSensitive: false,
        phraseSearch: true   
      });

      setTimeout(() => {
        const pageContents = this.pdfFindController._pageContents
        const pageMatches = this.pdfFindController.pageMatches
        const matches = pageMatches.flatMap((pm, i) => {
          if (pm.length) {
            const str = pageContents[i]
            return pm.map((x, j) => ({
              pageIndex: i,
              matchIndex: j,
              matchLocation: x,
              matchStr: `${str.slice( Math.max(0, x - 20), Math.min(str.length, x + 20) )}...`
            }))
          }
          return []
        })
        
        this.SET_MATCHES(matches)
      }, 3000)
    },

    querySwitch() {
      this.eventBus.dispatch("find", {
        type: "again",
        query: this.textQuery,
        findPrevious: false,
        caseSensitive: false,
        phraseSearch: true
      });
    },

    paginatorOn(paginator) {
      if (paginator) {
        this.pdfViewer.currentPageNumber = this.currentPage
        this.SET_PAGINATOR(false)
      }
    },

    'pdfViewer.currentPageNumber': {
      deep: true,
      handler(page) {
        if (!this.paginatorOn) {
          this.SET_CURRENT_PAGE(page)
        }
      }
    },

    fit(value) {
      if (value) {
        this.pdfViewer.currentScaleValue = value === 'width' ? 'page-width' : '1'
      }
    },
    
    scale(value) {
      this.pdfViewer.currentScaleValue = value;
    },

    rotation(value) {
      this.pdfViewer.pagesRotation = value;
    }
  },

  methods: {
    ...mapMutations({
      SET_PDF_SCALE: 'pdf/scale',
      SET_PDF_ROTATION: 'pdf/rotation',
      SET_CURRENT_PAGE: 'pdf/currentPage',
      SET_PAGE_COUNT: 'pdf/pageCount',
      SET_PAGINATOR: 'pdf/paginatorOn',
      SET_MATCHES: 'pdf/matches',
      SET_FILE: 'media/setFile',
      SET_FILE_TYPE: 'media/setFileType',
      SET_PDF_DOCUMENT: 'pdf/setPdfDocument',
      SET_CONTENT: 'SET_CONTENT'
    }),

    async getContextLen() {
      const filename = `${this.file._id}.pdf`
      const queryStr = `index_name=pdf/${encodeURIComponent(filename)}`
      const response = await fetch(`${process.env.VUE_APP_LANGCHAIN_API}/verify_index?${queryStr}`)
      if (response) {
        const indexArray = await response.json()
        return indexArray.length
      } else return null
    },

    async scrollToOutline(i) {
      const { dest }  = this.pdfOutline[i]
      let explicitDest, pageNumber;
      if (typeof dest === "string") explicitDest = await this.pdfDocument.getDestination(dest);
      else explicitDest = dest;
      
      if (Array.isArray(explicitDest)) {
        const [destRef] = explicitDest;

        if (typeof destRef === "object" && destRef !== null) {
          try {
            pageNumber = (await this.pdfDocument.getPageIndex(destRef)) + 1;
          } catch (err) {
            // Invalid page reference, ignore it and continue parsing.
          }
        } else if (Number.isInteger(destRef)) {
          pageNumber = destRef + 1
        }
      } else {
        pageNumber = explicitDest
      }
      
      if (pageNumber) this.scrollToPage(pageNumber)
    },

    scrollToPage(pageNum) {
      this.SET_CURRENT_PAGE(pageNum)
      this.pdfLinkService.page = pageNum;
    },

    scrollToMatch(index) {
      if (this.matches.length > 0) {
        const match = this.matches[index]
        this.pdfFindController.selected.pageIdx = match.pageIndex;
        this.pdfFindController.selected.matchIdx = match.matchIndex;
        this.pdfFindController._offset.pageIdx = match.pageIndex;
        this.pdfFindController._offset.matchIdx = match.matchIndex;
        this.pdfLinkService.page = match.pageIndex + 1;
        this.eventBus.dispatch('updatetextlayermatches', {
          source: this,
          pageIndex: match.pageIndex
        })
      }
    }
  }
}

</script>

<style scoped>
#viewerContainer {
  position: absolute;
  overflow: auto;
  background-color: rgb(49, 49, 54);
  scrollbar-color: #5e5e5e #a50a0a00;
  height: calc(100vh - 60px);
  width: 100vw;
}

#viewerContainer::-webkit-scrollbar {
  width: 12px;
}

#viewerContainer::-webkit-scrollbar-corner {
  background: transparent;
}

#viewerContainer::-webkit-scrollbar-track-piece  {
  background: transparent;
}
 
#viewerContainer::-webkit-scrollbar-track {
  background: transparent;
}
 
#viewerContainer::-webkit-scrollbar-thumb {
  background-color: #565656;
  min-height: 40px;
  /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(49, 49, 54); /* creates padding around scroll thumb */
}

#viewerContainer, ::before, ::after {
  -webkit-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
</style>

<style>
.textLayer .highlight {
  margin: -1px;
  padding: 1px;

  background-color: blueviolet;
  border-radius: 4px;
}

.textLayer ::-moz-selection {
  background: blueviolet;
}

.textLayer ::selection {
  background: rgb(99, 0, 197);
}
</style>